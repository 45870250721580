import React from 'react'
import { StaticQuery } from 'gatsby'
import { graphql } from 'gatsby' 
import StaffRow from './StaffRow'


class StaffMain extends React.Component {
  render() {

    const { data, props } = this.props;
    const { edges: staff } = data.allMarkdownRemark;

    if(props.type && props.type === 'row'){
      let name = props.contact;
      let contact = staff.find(({node: staff}) => staff.frontmatter.name === name);
      let contactObject = contact.node.frontmatter;

      return(
        <div> <StaffRow contact={contactObject}/></div>
      )
    } else{
      return(
        <div> Contacts list </div>
      )
    }

      
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query StaffMainQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "staff" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                name
                phone
                email
                description
                image{
                  childImageSharp{
                    fluid(maxWidth: 400, quality: 100){
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                templateKey
              }
            }
          }
        }
      }
    `}
    render={(data) => <StaffMain data={data} props={props} />}
  />
)
