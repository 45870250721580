import React from 'react';

import Avatar from '@material-ui/core/Avatar';

export default function StaffRow({contact}){
    let image = contact.image.childImageSharp.fluid.src

    const AvatarStyle = {
        height: '80px',
        width: '80px',
        marginLeft: '15px'
    }
    const itemStyle = {
        display: 'flex'
    }
    const rowStyle = {
        padding: '15px 0'
    }
    const pStyle={
        margin: '0',
        padding: '0'
    }

    return(
        <div className="staff-row" style={rowStyle}>
            <div className="staff-row-item"style={itemStyle}>
                <Avatar alt="Remy Sharp" src={image} style={AvatarStyle}/>
                <div style={{marginLeft: '15px'}}>
                    <h4>{contact.name}</h4>
                    <p style={pStyle}>{contact.phone}</p>
                    <p style={pStyle}>{contact.email}</p>
                </div>
            </div>
        </div>
    )
}