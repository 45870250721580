import React from 'react'
import PropTypes from 'prop-types'
//import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql} from 'gatsby'

import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Breadcrumb from '../components/Breadcrumb'
import StaffMain from '../components/Staff/StaffMain'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container'

export const EventTemplate = ({
    date,
    title,
    content,
    contentComponent,
    imgSrc,
    place,
    location,
    time,
    contactPerson,
    description,
    
    helmet
}) => {
  const EventContent = contentComponent || Content
  return (
    <section className="section article event">
      <Breadcrumb path='/event/' title={title}/>
      {helmet || ''}
      <Container className="content">
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <div className="single-event">
              <div className="article-intro">
                <div className="event-title article-mobile-title">
                  <h1 className="title">
                    {title}
                  </h1>
                </div>
                {imgSrc && imgSrc !== 'none' ? (
                  <div className="article-img" style={{backgroundImage: `url(${imgSrc})`}}>
                  </div>
                ) : ''}
              </div>
              <div className="article-body">
                <div className="description">
                  <h3>{description}</h3>
                </div>
                <EventContent content={content} />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
              <div className="event-info">
                <Card className="side-card">
                  <CardHeader className="title" title="Information"/>
                  <CardContent>
                    <List>
                      <ListItem>
                        <ListItemIcon>
                          <Icon>place</Icon>
                        </ListItemIcon>
                        <ListItemText primary={place} />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <Icon>calendar_today</Icon>
                        </ListItemIcon>
                        <ListItemText primary={date} />
                      </ListItem>
                      <ListItem>
                      <ListItemIcon>
                        <Icon>access_time</Icon>
                      </ListItemIcon>
                      <ListItemText primary={time} />
                    </ListItem>
                  </List>
                  </CardContent>
                </Card>
              </div>
              <div className="event-contact">
                  <Card className="side-card">
                    <CardHeader className="title" title="Kontaktperson"/>
                    <CardContent>
                      <StaffMain type={'row'} contact={contactPerson}/>
                    </CardContent>
                  </Card>
              </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}

EventTemplate.propTypes = {
  title: PropTypes.string
}

const EventPost = ({location, data }) => {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <EventTemplate
        helmet={
            <Helmet titleTemplate="%s | Event">
                <title>{`${post.frontmatter.title}`}</title>
                <meta
                name="description"
                content={`${post.frontmatter.description}`}
                />
          </Helmet>
        }
        date={post.frontmatter.date}
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        title={post.frontmatter.title}
        place={post.frontmatter.location}
        time={post.frontmatter.time}
        contactPerson={post.frontmatter.contact}
        imgSrc={post.frontmatter.image ? post.frontmatter.image.childImageSharp.fluid.src : 'none'}
        location={location}
      />
    </Layout>
  )
}

EventPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default EventPost

export const pageQuery = graphql`
  query EventPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "YYYY-MM-DD", locale: "sv")
        title
        description
        location
        time
        contact
        image {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
